import Vue from 'vue'
import api from '@/app/quadro-implantacao/shared/services/api'

const state = {
  colunas: [],
}

const getters = {
  colunas: state_ => state_.colunas,
}

const actions = {
  setColunas({ commit }, colunas) {
    commit('SET_COLUNAS', colunas)
  },

  removerCartaoDaColuna({ commit }, { colunaId, cartaoId }) {
    const { colunas } = state
    const indexColuna = colunas.findIndex(coluna => coluna.id === colunaId)
    const indexCartao = colunas[indexColuna].cartoes.findIndex(cartao => cartao.id === cartaoId)
    colunas[indexColuna].cartoes.splice(indexCartao, 1)
    colunas[indexColuna].quantidadeCartoes -= 1
    commit('SET_COLUNAS', colunas)
  },

  adicionarCartaoDaColuna({ commit }, cartao) {
    const { colunas } = state
    const indexColuna = colunas.findIndex(coluna => coluna.id === cartao.colunaId)
    colunas[indexColuna].cartoes.unshift(cartao)
    colunas[indexColuna].quantidadeCartoes += 1
    commit('SET_COLUNAS', colunas)
  },

  atualizarCartaoNaLista({ commit }, cartaoId) {
    api.getCartaoCapaById(cartaoId)
      .then(payload => {
        const { colunas } = state
        const indexColuna = colunas.findIndex(coluna => coluna.id === payload.data.colunaId)
        const indexCartao = colunas[indexColuna].cartoes.findIndex(card => card.id === payload.data.id)
        colunas[indexColuna].cartoes[indexCartao] = payload.data
        commit('SET_COLUNAS', colunas)
      })
      .catch(err => {
        throw err
      })
  },

}

const mutations = {
  SET_COLUNAS(state_, colunas) {
    Vue.set(state_, 'colunas', colunas)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
