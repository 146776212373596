export default [{
  path: '/niveis-atendimentos',
  component: {
    name: 'nivelAtendimentoBase',
    template: '<router-view />',
    resource: 'nivelatendimentos',
    meta: {
      resource: 'nivelatendimentos',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'niveis-atendimentos',
      component: () => import('@/app/nivel-atendimento/NivelAtendimentoView.vue'),
      meta: {
        resource: 'nivelatendimentos',
        action: 'read',
        navActiveLink: 'niveis-atendimentos',
        pageTitle: 'Nível de Atendimentos',
        breadcrumb: [
          { text: 'Nível de Atendimentos', to: { name: 'niveis-atendimentos' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-nivel-atendimento',
      component: () => import('@/app/nivel-atendimento/shared/components/NivelAtendimento.vue'),
      meta: {
        resource: 'nivelatendimentos',
        action: 'create',
        navActiveLink: 'niveis-atendimentos',
        pageTitle: 'Cadastro de Nível de Atendimento',
        breadcrumb: [
          {
            text: 'Nível de Atendimentos',
            to: { name: 'niveis-atendimentos' },
          },
          {
            text: 'Novo',
            to: { name: 'categoria' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-nivel-atendimento',
      component: () => import('@/app/nivel-atendimento/shared/components/NivelAtendimento.vue'),
      meta: {
        resource: 'nivelatendimentos',
        action: 'update',
        navActiveLink: 'niveis-atendimentos',
        pageTitle: 'Editar Nível de Atendimento',
        breadcrumb: [
          { text: 'Nível de Atendimentos', to: { name: 'niveis-atendimentos' } },
          { text: 'Editar', to: { name: 'editar-nivel-atendimento' }, active: true },
        ],
      },
    },
  ],
}]
