export default [
  {
    path: '/tarefas/abertas',
    component: {
      name: 'tarefasBase',
      template: '<router-view />',
      resource: 'implantacao',
      meta: {
        resource: 'implantacao',
        action: 'read',
        breadcrumb: [
          { text: 'Home', to: { path: '/' } },
        ],
      },
    },
    children: [
      {
        path: 'visualizar',
        name: 'tarefas',
        component: () => import('@/app/quadro-implantacao/QuadroImplantacaoView.vue'),
        meta: {
          resource: 'implantacao',
          action: 'read',
          navActiveLink: 'tarefas',
          pageTitle: 'Visualizar Tarefas',
        },
      },
      {
        path: 'quadros',
        name: 'quadros',
        component: () => import('@/app/quadro-implantacao/shared/components/QuadroImplantacao.vue'),
        meta: {
          resource: 'implantacao',
          action: 'read',
          navActiveLink: 'tarefasAbertasQuadros',
        },
      },
    ],
  },
  {
    path: '/tarefas/arquivadas',
    component: {
      name: 'tarefasBase',
      template: '<router-view />',
      resource: 'implantacao',
      meta: {
        resource: 'implantacao',
        action: 'read',
      },
    },
    children: [
      {
        path: 'visualizar',
        name: 'tarefasArquivadas',
        component: () => import('@/app/quadro-implantacao/QuadroImplantacaoView.vue'),
        meta: {
          resource: 'implantacao',
          action: 'read',
          navActiveLink: 'tarefasArquivadas',
          pageTitle: 'Visualizar tarefas arquivadas',
        },
      },
      {
        path: 'quadros-arquivados',
        name: 'quadrosArquivados',
        component: () => import('@/app/quadro-implantacao/shared/components/QuadroImplantacao.vue'),
        meta: {
          resource: 'implantacao',
          action: 'read',
          navActiveLink: 'tarefasArquivadas',
          pageTitle: 'Quadros de tarefas arquivadas',
        },
      },
    ],
  },
  {
    path: '/tarefas/inativas',
    component: {
      name: 'tarefasInativas',
      template: '<router-view />',
      resource: 'implantacao',
      meta: {
        resource: 'implantacao',
        action: 'read',
      },
    },
    children: [
      {
        path: 'visualizar',
        name: 'tarefasInativas',
        component: () => import('@/app/quadro-implantacao/shared/components/TarefasInativas.vue'),
        meta: {
          resource: 'implantacao',
          action: 'read',
          pageTitle: 'Visualizar tarefas inativas',
        },
      },
    ],
  },
]
